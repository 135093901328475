import React, { useCallback, useRef } from "react";

import clsx from "clsx";

import { useSearchParams } from "../../../../../hooks";

import { CardButton } from "../../../../common/CardButton/CardButton";
import { useForm } from "../../../../../hooks/useForm";
import { Required } from "../../../../../constants/errors";
import { CREATE_CONTACT_REQUEST } from "../../../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { useRouter } from "next/router";
import { ReferralTextContainer } from "./ReferralTextContainer";
import { useTranslation } from "next-i18next";
import PhoneInputContainer from "../../../../common/SignUp/PhoneInputContainer";

const RequestFormComponent = ({ onResponse, buttonId }) => {
  const [t] = useTranslation();

  const router = useRouter();
  const {
    query: { slug: partner },
  } = router;

  const queries = useSearchParams();
  const [createContactRequest] = useMutation(CREATE_CONTACT_REQUEST);

  const onSubmit = useCallback(
    (values) => {
      const { site, name, phone } = values;

      const referral = queries.get("referral");

      createContactRequest({
        variables: {
          ...(partner && { partnerSlug: partner }),
          name,
          phone,
          clubName: site,
          ...(referral && { referral }),
        },
      }).then(
        () => {
          onResponse({ hasError: false, msg: "ok" });
        },
        ({ graphQLErrors, message }) => {
          if (graphQLErrors?.[0]?.result == 5) return;

          onResponse({ hasError: true, msg: message });
        }
      );
    },
    [partner, onResponse, createContactRequest]
  );

  const phoneRef = useRef();

  const validate = useCallback((values) => {
    const { name, phone } = values;
    const errors = {};
    const requireField = t("required_field");
    if (Required(name)) errors.name = requireField;

    if (Required(phone)) errors.phone = requireField;

    return errors;
  }, []);

  const slug = queries.get("referral");

  const {
    touched,
    values: { site, name, phone },
    errors,
    handleInputChange,
    handleSubmit,
    handleBlur,
  } = useForm({
    initialState: {
      name: "",
      phone: "",
      site: "",
    },
    onSubmit,
    validate,
  });

  return (
    <div className="request-form">
      <div
        className={
          "sign-up-section__input-wrapper sign-up-section__input-wrapper_required"
        }
      >
        <input
          required
          type="text"
          name="name"
          value={name}
          onBlur={handleBlur}
          placeholder={t("first_name")}
          onChange={handleInputChange}
          className={clsx("sign-up-section__input request-form__input", {
            "sign-up-section__input_error": errors.name && touched.name,
          })}
        />
        {errors.name && touched.name && (
          <div className="sign-up-section__input-error-message">
            <p>{errors.name}</p>
          </div>
        )}
      </div>
      <div
        className={
          "sign-up-section__input-wrapper sign-up-section__input-wrapper_required"
        }
      >
        <PhoneInputContainer
          placeholder={t("phone")}
          errorMessage={errors.phone && touched.phone && errors.phone}
          onBlur={handleBlur}
          onChange={handleInputChange.bind(phoneRef)}
          inputRef={phoneRef}
          allCountries={true}
          inputProps={{
            // value: phone,
            name: "phone",
          }}
        />

        {errors.phone && touched.phone && (
          <div className="sign-up-section__input-error-message">
            <p>{errors.phone}</p>
          </div>
        )}
      </div>
      <div
        className={
          "sign-up-section__input-wrapper sign-up-section__input-wrapper_required"
        }
      >
        <input
          required
          type="text"
          name="site"
          value={site}
          onBlur={handleBlur}
          placeholder={t("club")}
          onChange={handleInputChange}
          className={clsx("sign-up-section__input request-form__input", {
            "sign-up-section__input_error":
              errors.site && touched.site && errors.site,
          })}
        />
        {errors.site && touched.site && errors.site && (
          <div className="sign-up-section__input-error-message">
            <p>{errors.site}</p>
          </div>
        )}
      </div>
      <CardButton
        title={t("send")}
        className="primary-button request-form__submit"
        type="submit"
        onClick={handleSubmit}
        id={buttonId}
      />

      <ReferralTextContainer slug={slug} />
    </div>
  );
};
export const RequestForm = RequestFormComponent;
