import React, { useState } from "react";
import { RequestForm } from "./RequestForm";

import { AlertBlock } from "../../../../common/AlertBlock";
import { useTranslation } from "next-i18next";
import {
  NOSUCCESS_SVG,
  SUCCESS_SVG,
} from "../../../../../constants/svgConstants";

export const RequestModal = ({ onClose, buttonId }) => {
  const [t] = useTranslation();
  const [{ hasError, msg }, setResponse] = useState({
    hasError: false,
    msg: "",
  });

  return (
    <>
      <AlertBlock onClose={onClose}>
        {!msg ? (
          <>
            <p className="request-form__title">{t("send_request")}</p>
            <p className="request-form__subtitle">{t("will_contact_you")}</p>
            <RequestForm
              onClose={onClose}
              onResponse={setResponse}
              buttonId={buttonId}
            />
          </>
        ) : !hasError ? (
          <>
            {SUCCESS_SVG}
            <h2
              className="sign-up-section__title"
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "35px",
              }}
            >
              {t("request_was_send")}
            </h2>
          </>
        ) : (
          <>
            {NOSUCCESS_SVG}
            <h2
              className="sign-up-section__title"
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "35px",
              }}
            >
              {msg}
            </h2>
          </>
        )}
      </AlertBlock>
      <style jsx global>{`
        @import "./RequestModal.scss?";
      `}</style>
    </>
  );
};
