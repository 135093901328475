
import { useCallback } from "react";
import { pageCity } from '../graphql/reactivities';
import { usePageCity } from '../utils/apollo/reactivities/useReactivities';

declare global {
  interface WindowEventMap {
    "local-storage": CustomEvent;
  }
}

export const useCityHook = (): { pageCity: string | null, changeCity: (x: void) => void, removeCity: (x: void) => void } => {

  const changeCity = useCallback((city) => {
    pageCity(city);
    localStorage.setItem("pageCity", JSON.stringify(city));
  }, []);

  const removeCity = useCallback(() => {

    localStorage.removeItem("pageCity");
    pageCity(undefined);
  }, []);


  const city = usePageCity();

  return { pageCity: city, changeCity, removeCity };
};
