import React from "react";

import { useTranslation } from "next-i18next";
import Image from "next/image";

function AdvantageSection({ advantages }) {
  const [t] = useTranslation();

  return (
    <section className="landing-advantage landing-main-block landing-main">
      <div className="container">
        <div className="landing-advantage__block" id="_advantage">
          <h3 className="landing-advantage__title landing-title">
            {t("system_advantages")}
          </h3>

          <div className="landing-advantage__inner">
            {[...advantages]
              .sort((a, b) => a.order - b.order)
              .map(({ id, title, description, icon }) => (
                <div className="landing-advantage__item-block-wrapper" key={id}>
                  <div className="landing-advantage__item-block">
                    <div className="landing-advantage__item-block-svg">
                      {icon && (
                        <Image
                          className="landing-advantage__item-image"
                          src={icon}
                          alt={title}
                          layout="fill"
                        />
                      )}
                    </div>

                    <div className="landing-advantage__item-block-inner">
                      <h4 className="landing-advantage__item-block-title">
                        {title}
                      </h4>
                      <p className="landing-advantage__item-block-text">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <style jsx global>{`
        @import "./AdvantageSection.scss?1";
      `}</style>
    </section>
  );
}

export default AdvantageSection;
