import React from "react";

import ClubsCards from "../../pagesComponents/Cities/components/ClubsCards/ClubsCards";
import { COUNTRY_ROUTE } from "../../pagesComponents/Cities/constants/ownConstants";
import { TOP_CLUBS } from "../../../graphql/queries";
import { useTranslation } from "next-i18next";
import { useQuery } from "@apollo/client";
import Link from "next/link";

const TopClubs = () => {
  const [t] = useTranslation();
  const { data: { clubs = [] } = {} } = useQuery(TOP_CLUBS);

  return (
    !!clubs.length && (
      <>
        <section className="landing-main-block landing-main">
          <div className="container">
            <h3 className="main-title main-title--center main-title--mb">
              {t("top_16_clubs")}
              <span className="accent-title">Instasport</span>
            </h3>
            <ClubsCards clubs={clubs} childClass={"top-club-card__wrapper"} />
            <div className="landing-partners__button-block">
              <Link href={COUNTRY_ROUTE}>
                <button className="landing-main-button">
                  {t("all_clubs")}
                </button>
              </Link>
            </div>
          </div>
        </section>

        <style jsx global>{`
          @import "../../pagesComponents/LandingPage/PartnersSection/PartnersSection.scss";
        `}</style>
      </>
    )
  );
};

export default TopClubs;
