import React, { useEffect } from "react";

import {
  CHECK_MARK_SVG,
  YOUTUBE_ICON_SVG,
  TELEGRAM_ICON_SVG,
  FACEBOOK_ICON_SVG,
  INSTAGRAM_ICON_SVG,
  HEADER_BACKGROUND_SVG,
} from "../../../../constants/svgConstants";
import { useToggle } from "../../../../hooks";

import { CardButton } from "../../../common/CardButton";
import Link from "next/link";

import { useTranslation } from "next-i18next";
import { HEADER_NAV_CONTENT } from "./languageConstants";
import { RequestModal } from "../RequestSection/RequestModal/RequestModal";
import Image from "next/image";
import landingHeaderImage from "../../../../../public/landingHeaderImage.png";
import { IS_FULL_CLIENT_SITE } from "../../../../constants/APIKeys";
import { useRouter } from "next/router";

function LandingHeader({ social = {}, isLanding = true }) {
  const [t, { language }] = useTranslation();
  const router = useRouter();

  const {
    query: { show_request_modal },
  } = router;

  const removeQueryParam = (param) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const {
    title: partnerName = "",
    visits,
    clubs,
    socialFacebook,
    socialInstagram,
    socialTelegram,
    socialYoutube,
    highlights = [],
  } = social;

  const [showModal, toggle, off] = useToggle(!!show_request_modal);

  useEffect(() => {
    if (!show_request_modal) return;

    removeQueryParam("show_request_modal");
  }, [show_request_modal]);

  return (
    <>
      {showModal && (
        <RequestModal
          onClose={off}
          buttonId={
            isLanding ? "_landing-consultation" : `_${partner}-consultation`
          }
        />
      )}
      <section className="landing-header landing-main">
        <div className="landing-header__container container">
          <div className="landing-header__wrapper">
            <div className="landing-header__inner">
              <h1 className="landing-header__main-title">
                {t("header_main_title")}{" "}
                <span className="landing-header__partner-name">
                  {partnerName ? partnerName : "Instasport"}
                </span>
                {!isLanding && partnerName && (
                  <span className="landing-header__partner-subtitle">
                    {" " + t("header_partner_title")}
                  </span>
                )}
              </h1>
              <h2 className="landing-header__subtitle">
                {t("header_subtitle")}
              </h2>
              <div className="landing-header__items-block">
                {[...highlights]
                  .sort((a, b) => a.order - b.order)
                  .map(({ id, title }) => (
                    <div key={id} className="landing-header__item">
                      <div className="landing-header__item-svg-block">
                        {CHECK_MARK_SVG}
                      </div>
                      <p className="landing-header__item-text">{title}</p>
                    </div>
                  ))}
              </div>
              <div className="landing-header__nav-buttons-wrapper">
                {HEADER_NAV_CONTENT.map(
                  ({ id, text, buttonTitle, urlSlug }) => (
                    <div key={id} className="landing-header__nav-button-inner">
                      {urlSlug === "addclub" ? (
                        <CardButton
                          title={t(buttonTitle)}
                          className="landing-header__nav-button landing-main-button"
                          onClick={toggle}
                        />
                      ) : (
                        <Link href={`/${language}/${urlSlug}`} passHref>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="landing-header__nav-button landing-main-button"
                          >
                            {t(buttonTitle)}
                          </a>
                        </Link>
                      )}

                      {isLanding && IS_FULL_CLIENT_SITE && (
                        <>
                          <p className="landing-header__nav-count-title">
                            {id === 1 ? visits : clubs}
                          </p>
                          <p className="landing-header__nav-text-title">
                            {t(text)}
                          </p>
                        </>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>

            <div className="landing-header__background-block">
              {/* BACHGROUND */}
              <div className="landing-header__background-svg-block">
                {HEADER_BACKGROUND_SVG}
              </div>

              <div className="landing-header__background-img-block">
                <Image
                  src={landingHeaderImage}
                  layout="responsive"
                  alt="header"
                  priority={true}
                />
              </div>
              {/* BACHGROUND */}

              <div className="landing-header__social-links-wrapper">
                <div className="landing-header__social-links-block">
                  <div className="landing-header__social-helpfull-block">
                    <div className="landing-header__social-youtube-block">
                      <p className="landing-header__social-subtitle">
                        {t("social_title1")}
                      </p>

                      <a
                        href={socialYoutube}
                        className="landing-header__social-helpfull-button youtube"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {YOUTUBE_ICON_SVG}
                        <span>Youtube</span>
                      </a>
                    </div>

                    <div className="landing-header__social-telegram-block">
                      <p className="landing-header__social-subtitle">
                        {t("social_title2")}
                      </p>

                      <a
                        href={socialTelegram}
                        className="landing-header__social-helpfull-button telegram"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {TELEGRAM_ICON_SVG}
                        <span>Telegram</span>
                      </a>
                    </div>
                  </div>

                  <div className="landing-header__social-connection-block">
                    <a
                      href={socialFacebook}
                      className="landing-header__social-connection-button facebook"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {FACEBOOK_ICON_SVG}
                    </a>

                    <a
                      href={socialInstagram}
                      className="landing-header__social-connection-button instagram"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span>{INSTAGRAM_ICON_SVG}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx global>{`
          @import "./LandingHeader.scss?";
        `}</style>
      </section>
    </>
  );
}

export { LandingHeader };
